import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { animated, useSpring, config } from "react-spring";
import MDXRenderer from "gatsby-mdx/mdx-renderer";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { SEO, Container, Layout, Hero } from "../components";

const Title = styled.h3`
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  margin: 0;
`;

const Single = ({ data: { mdx }, location }) => {
  const single = mdx.frontmatter;

  return (
    <Layout pathname={location.pathname} customSEO>
      <SEO pathname={location.pathname} postNode={mdx} single />
      <Container type="text">
        <Title>{single.title}</Title>
        <MDXRenderer>{mdx.code.body}</MDXRenderer>
      </Container>
    </Layout>
  );
};

export default Single;

Single.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.object.isRequired
  }).isRequired,
  location: PropTypes.object.isRequired
};

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      code {
        body
      }
      fields {
        slug
      }
      frontmatter {
        title
        cover {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 800) {
              src
            }
          }
        }
      }
    }
  }
`;
